import { cn, useLG } from "@/lib/utils";
import ButtonIcon from "@/components/web/buttons/ButtonIcon";
import { IconBurgerMenu, IconChevron, IconClose } from "@/components/web/icons";
import { LogoNeueLebenColor } from "@/components/web/icons/logos/LogoNeueLebenColor";
import LinkNavbar from "../links/LinkNavbar";
import { useEffect, useState } from "react";
import { NAV_LINKS } from "../landingpage/MenuData";
import { AnimatePresence, motion } from "framer-motion";
import store from "stores/store";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { inject, observer } from "mobx-react";

const Navbar: React.FC = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const LG = useLG();

    const [menuZIndex, setMenuZIndex] = useState(1);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [subMenuIndex, setSubMenuIndex] = useState<number | null>(null);
    const [desktopMenuIndex, setDesktopMenuIndex] = useState<number | null>(null);
    const [subMenuNestedIndex, setSubMenuNestedIndex] = useState<number | null>(null);
    const [subMenuNestedLastIndex, setSubMenuNestedLastIndex] = useState<number | null>(null);

    const { startLocalized } = store;

    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? "hidden" : "visible";
        if (!isMenuOpen) {
            setSubMenuIndex(null);
            setSubMenuNestedIndex(null);
            setSubMenuNestedLastIndex(null);
        }

        return () => {
            document.body.style.overflow = "visible";
        };
    }, [isMenuOpen]);

    const handleSubmenuToggle = (index: number) => {
        setSubMenuIndex(subMenuIndex === index ? null : index);
        setSubMenuNestedIndex(null);
    };

    const handleNestedSubmenuToggle = (index: number) => {
        setSubMenuNestedIndex(subMenuNestedIndex === index ? null : index);
    };

    const handleLastNestedSubmenuToggle = (index: number) => {
        setSubMenuNestedLastIndex(subMenuNestedLastIndex === index ? null : index);
    };

    const handleDesktopmenuToggle = (index: number) => {
        setDesktopMenuIndex(desktopMenuIndex === index ? null : index);
        setSubMenuIndex(null);
    };

    const renderSublinkLast = (isDesktopmenuOpen: number, isSubmenuOpen: number, isNestedSubmenuOpen: number) => {
        const sublinks = NAV_LINKS[isDesktopmenuOpen].links[isSubmenuOpen].sublinks;

        if (!sublinks) {
            return;
        }
        const result = sublinks[isNestedSubmenuOpen]?.sublinks?.map((nestedSublink, nestedSublinkIndex) => (
            <li key={nestedSublinkIndex} className=" flex items-start w-full border-t border-nlNavBg first:border-none">
                <button className={cn("flex justify-between items-center w-full px-[12px] py-[10px] hover:bg-nlNavBg")}>
                    <a href={nestedSublink.href}>{nestedSublink.label}</a>
                </button>
            </li>
        ));

        return result;
    };

    return (
        <>
            <div
                className={cn(
                    "flex bg-white w-full shadow-black-65 z-50",
                    "h-[130px] p-[16px] max-h-[130px]",
                    "lg:h-[185px] lg:pb-[35px] lg:px-[86px] lg:pt-[25px] lg:max-h-[185px]",
                )}
            >
                <div className={cn("flex w-full bg-white", "items-end justify-between", "lg:block")}>
                    {startLocalized?.logo !== undefined && startLocalized?.logo !== null && (
                        <div className="relative min-w-[150px] min-h-[52px] w-[150px] h-[52px] lg:h-[70px] lg:w-[200px] lg:mb-[23px]">
                            <ExportedImage
                                src={getImageUrlFromGeneralImage(startLocalized?.logo)}
                                alt={getAltTextFromGeneralImage(startLocalized?.logo)}
                                layout="fill"
                                objectPosition="center"
                            />
                        </div>
                    )}
                    {!LG && (
                        <ButtonIcon onClick={() => setMenuOpen(!isMenuOpen)}>
                            {isMenuOpen ? <IconClose color="#FFFFFF" /> : <IconBurgerMenu />}
                        </ButtonIcon>
                    )}
                    {LG && (
                        <>
                            <div className="flex lg:mb-[25px]">
                                {NAV_LINKS.map((item, index) => (
                                    <LinkNavbar key={index} onClick={() => handleDesktopmenuToggle(index)}>
                                        {item.title}
                                    </LinkNavbar>
                                ))}
                            </div>
                        </>
                    )}
                    {LG && desktopMenuIndex !== null && (
                        <div className="w-full bg-white px-[30px] pt-[20px] pb-[40px] flex gap-[40px] shadow-navbarDesktop">
                            <ul className="text-nlNavText font-frutigerRoman font-normal xl:w-[333px] lg:w-[273px]">
                                {NAV_LINKS[desktopMenuIndex].links.map((link, linkIndex) => (
                                    <li
                                        key={linkIndex}
                                        className=" flex items-start border-t border-nlNavBg first:border-none"
                                    >
                                        {link.sublinks ? (
                                            <>
                                                <button
                                                    className={cn(
                                                        "flex justify-between items-center w-full px-[12px] py-[10px] hover:bg-nlNavBg",
                                                        [subMenuIndex === linkIndex && "bg-nlNavBg"],
                                                    )}
                                                    onClick={() => handleSubmenuToggle(linkIndex)}
                                                >
                                                    {link.label}
                                                    <IconChevron
                                                        width="16"
                                                        height="16"
                                                        color={subMenuIndex === linkIndex ? "#DD0B2F" : "#a69e9e"}
                                                    />
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                className={cn(
                                                    "flex justify-between items-center w-full px-[12px] py-[10px] hover:bg-nlNavBg",
                                                )}
                                            >
                                                <a href={link.href}>{link.label}</a>
                                            </button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            {subMenuIndex !== null &&
                                subMenuIndex !== undefined &&
                                desktopMenuIndex !== null &&
                                desktopMenuIndex !== undefined && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.4, ease: "easeInOut" }}
                                    >
                                        <ul className="text-nlNavText font-frutigerRoman font-normal xl:w-[333px] lg:w-[273px]">
                                            {NAV_LINKS[desktopMenuIndex].links[subMenuIndex].sublinks?.map(
                                                (sublink, sublinkIndex) => (
                                                    <li
                                                        key={sublinkIndex}
                                                        className="flex items-start border-t border-nlNavBg first:border-none"
                                                    >
                                                        {sublink.sublinks ? (
                                                            <>
                                                                <button
                                                                    className={cn(
                                                                        "flex justify-between items-center w-full px-[12px] py-[10px] hover:bg-nlNavBg",
                                                                        [
                                                                            subMenuNestedIndex === sublinkIndex &&
                                                                                "bg-nlNavBg",
                                                                        ],
                                                                    )}
                                                                    onClick={() =>
                                                                        handleNestedSubmenuToggle(sublinkIndex)
                                                                    }
                                                                >
                                                                    {sublink.label}
                                                                    <IconChevron
                                                                        width="16"
                                                                        height="16"
                                                                        color={
                                                                            subMenuNestedIndex === sublinkIndex
                                                                                ? "#DD0B2F"
                                                                                : "#a69e9e"
                                                                        }
                                                                    />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button
                                                                className={cn(
                                                                    "flex justify-between items-center w-full px-[12px] py-[10px] hover:bg-nlNavBg",
                                                                )}
                                                            >
                                                                <a href={sublink.href}>{sublink.label}</a>
                                                            </button>
                                                        )}
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </motion.div>
                                )}
                            {subMenuIndex !== null && subMenuNestedIndex !== null && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.4, ease: "easeInOut" }}
                                >
                                    <ul className="text-nlNavText font-frutigerRoman font-normal xl:w-[333px] lg:w-[273px]">
                                        {renderSublinkLast(desktopMenuIndex, subMenuIndex, subMenuNestedIndex)}
                                    </ul>
                                </motion.div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <AnimatePresence>
                {!LG && isMenuOpen && (
                    <motion.div
                        className="absolute top-[-1px] w-full"
                        initial={{ y: "-10%", opacity: 0 }}
                        animate={{ y: 88.75, opacity: 1 }}
                        exit={{ y: "-10%", opacity: 0, zIndex: 10 }}
                        transition={{ duration: 0.4, ease: "easeInOut" }}
                        style={{ zIndex: menuZIndex }}
                        onUpdate={(latest) => {
                            const yValue = typeof latest.y === "string" ? parseFloat(latest.y) : latest.y;
                            if (yValue >= 88.75 && menuZIndex !== 51) {
                                setMenuZIndex(51);
                            } else if (yValue < 0 && menuZIndex !== 1) {
                                setMenuZIndex(1);
                            }
                        }}
                    >
                        <div className="flex w-full justify-center">
                            <ul className="w-full bg-white text-nlNavText text-base font-frutigerRoman font-bold sm:max-w-[510px] md:max-w-[690px] lg:max-w-[930px] xl:max-w-[1110px] lg:w-[1110px] max-h-[calc(100vh-88.75px)] overflow-y-auto ">
                                {/*----------------------- FIRST LEVEL LINKS -----------------------*/}
                                {NAV_LINKS.map((item, index) => (
                                    <li
                                        className={cn("border-t border-nlNavBg first:border-none", [
                                            subMenuIndex === index && "bg-nlNavBg text-primary-500",
                                        ])}
                                        key={index}
                                    >
                                        <button
                                            className="flex items-center justify-between w-full p-[15px]"
                                            onClick={() => handleSubmenuToggle(index)}
                                        >
                                            {item.title}
                                            <IconChevron
                                                width="16"
                                                height="16"
                                                color="#a69e9e"
                                                className={cn("-rotate-90", subMenuIndex === index && "rotate-90")}
                                            />
                                        </button>
                                        {/*----------------------- SECOND LEVEL LINKS -----------------------*/}
                                        {subMenuIndex === index && (
                                            <ul className="w-full bg-nlNavBg text-nlNavText text-base font-frutigerRoman font-normal">
                                                {item.links.map((link, linkIndex) =>
                                                    link.sublinks ? (
                                                        <li
                                                            key={linkIndex}
                                                            className={cn(
                                                                subMenuNestedIndex === linkIndex && "text-primary-500",
                                                            )}
                                                        >
                                                            <button
                                                                key={linkIndex}
                                                                className="flex items-center justify-between w-full p-[15px] border-t border-white"
                                                                onClick={() => handleNestedSubmenuToggle(linkIndex)}
                                                            >
                                                                {link.label}
                                                                <IconChevron
                                                                    width="16"
                                                                    height="16"
                                                                    color="#a69e9e"
                                                                    className={cn(
                                                                        "-rotate-90",
                                                                        subMenuIndex === index && "rotate-90",
                                                                    )}
                                                                />
                                                            </button>
                                                            {subMenuNestedIndex === linkIndex && (
                                                                <ul className="w-full bg-nlNavBg text-nlNavText text-base font-frutigerRoman font-normal">
                                                                    {link.sublinks.map((sublink, sublinkIndex) =>
                                                                        sublink.sublinks ? (
                                                                            <li
                                                                                key={sublinkIndex}
                                                                                className={cn(
                                                                                    "pl-[15px] pr-[15px]",
                                                                                    subMenuNestedLastIndex ===
                                                                                        sublinkIndex &&
                                                                                        "text-primary-500",
                                                                                )}
                                                                            >
                                                                                <button
                                                                                    className="flex items-center justify-between w-full p-[15px] border-t border-white"
                                                                                    onClick={() =>
                                                                                        handleLastNestedSubmenuToggle(
                                                                                            sublinkIndex,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {sublink.label}
                                                                                    <IconChevron
                                                                                        width="16"
                                                                                        height="16"
                                                                                        color="#a69e9e"
                                                                                        className={cn(
                                                                                            "-rotate-90",
                                                                                            subMenuNestedIndex ===
                                                                                                linkIndex &&
                                                                                                "rotate-90",
                                                                                        )}
                                                                                    />
                                                                                </button>
                                                                                {subMenuNestedLastIndex ===
                                                                                    sublinkIndex && (
                                                                                    <ul className="w-full bg-white text-nlNavText text-base font-frutigerRoman font-normal ml-[20px]">
                                                                                        {sublink.sublinks.map(
                                                                                            (
                                                                                                lastSublink,
                                                                                                lastSublinkIndex,
                                                                                            ) => (
                                                                                                <li
                                                                                                    key={
                                                                                                        lastSublinkIndex
                                                                                                    }
                                                                                                    className="pl-[20px] pr-[15px]"
                                                                                                >
                                                                                                    <a
                                                                                                        href={
                                                                                                            lastSublink.href
                                                                                                        }
                                                                                                        className="flex items-center justify-between w-full py-[15px] border-t border-neutral-200 first:border-none"
                                                                                                    >
                                                                                                        {
                                                                                                            lastSublink.label
                                                                                                        }
                                                                                                    </a>
                                                                                                </li>
                                                                                            ),
                                                                                        )}
                                                                                    </ul>
                                                                                )}
                                                                            </li>
                                                                        ) : (
                                                                            <li
                                                                                key={sublinkIndex}
                                                                                className="pl-[15px] pr-[15px]"
                                                                            >
                                                                                <a
                                                                                    href={sublink.href}
                                                                                    className="flex items-center justify-between w-full p-[15px] border-t border-white"
                                                                                >
                                                                                    {sublink.label}
                                                                                </a>
                                                                            </li>
                                                                        ),
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    ) : (
                                                        <li
                                                            className="p-[15px] border-t border-white first:border-none"
                                                            key={linkIndex}
                                                        >
                                                            {link.label}
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default inject("store")(observer(Navbar));
