import { cn, useLG } from "@/lib/utils";
import { LogoNeueLebenWhite } from "../icons/logos/LogoNeueLebenWhite";

type FooterProps = {
    className?: string;
};

const Footer: React.FC<FooterProps> = ({ className }) => {
    const LG = useLG();
    return (
        <div
            className={cn(
                "bg-neutral-300 px-[16px] pt-[32px] pb-[64px] flex w-full flex-col items-center h-[276px] gap-[24px] max-h-[276px]",
                "font-frutigerRoman text-[12px] leading-[135%] text-white",
                "lg:flex-row lg:py-[32px] lg:px-[86px] lg:justify-between lg:h-[116px] lg:max-h-[116px]",
                className,
            )}
        >
            <LogoNeueLebenWhite className="w-[149px] h-[52.5px]" />
            <div>@ neue leben Lebensversicherung AG 2024</div>
            <div className={cn("flex flex-col items-center gap-2", "lg:flex-row")}>
                <a>Impressum</a>
                <a>Datenschutz</a>
                <a>Kontakt</a>
            </div>
        </div>
    );
};

export default Footer;
