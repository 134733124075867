import { IconProps } from "@/components/web/icons/types";
import { cn } from "@/lib/utils";

export const LogoNeueLebenWhite: React.FC<IconProps> = ({ className }) => {
    return (
        <div className={cn("flex h-[52.5px] w-[149px] items-center justify-center", className)}>
            <svg width="149" height="52.5" viewBox="0 0 1470 484" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_804_316)">
                    <path
                        d="M415.986 144.448C409.501 64.0725 342.154 0.692871 259.283 0.692871C176.844 0.692871 109.079 64.0725 102.558 144.448H114.179C118.788 96.5198 159.329 59.0157 208.7 59.0157C258.071 59.0157 299.03 96.5198 303.69 144.448H415.986ZM0.461914 164.401C7.05525 244.906 74.6044 308.156 157.194 308.156C239.77 308.156 307.312 244.906 313.905 164.401H303.185C296.787 210.504 256.758 246.067 208.7 246.067C160.642 246.067 121.414 210.504 114.792 164.401H0.461914Z"
                        fill="white"
                    />
                    <path
                        d="M355.722 215.763H375.747V233.588H376.187C382.347 220.827 397.309 213.123 412.926 213.123C441.976 213.123 454.961 231.164 454.961 261.31V327.979H434.272V269.887C434.272 243.709 428.551 230.94 410.51 229.844C386.964 229.844 376.403 248.765 376.403 276.048V327.979H355.722V215.763Z"
                        fill="white"
                    />
                    <path
                        d="M562.763 261.094C562.763 243.925 552.642 229.844 534.824 229.844C514.582 229.844 506.215 246.565 504.678 261.094H562.763ZM576.188 323.355C568.267 326.219 559.25 330.62 540.761 330.62C500.934 330.62 482.669 306.417 482.669 269.887C482.669 236.661 503.574 213.123 535.264 213.123C572.667 213.123 584.772 240.405 584.772 276.488H504.678C504.678 298.713 522.056 313.898 541.641 313.898C555.282 313.898 571.124 306.85 576.188 302.674V323.355Z"
                        fill="white"
                    />
                    <path
                        d="M711.719 327.979H691.694V310.154H691.261C685.093 322.915 670.132 330.619 654.514 330.619C625.465 330.619 612.487 312.571 612.487 282.432V215.763H633.169V273.855C633.169 300.033 638.89 312.802 656.931 313.898C680.477 313.898 691.037 294.976 691.037 267.694V215.763H711.719V327.979Z"
                        fill="white"
                    />
                    <path
                        d="M819.529 261.094C819.529 243.925 809.408 229.844 791.583 229.844C771.341 229.844 762.981 246.565 761.437 261.094H819.529ZM832.946 323.355C825.033 326.219 816.009 330.62 797.527 330.62C757.7 330.62 739.442 306.417 739.442 269.887C739.442 236.661 760.34 213.123 792.03 213.123C829.433 213.123 841.538 240.405 841.538 276.488H761.437C761.437 298.713 778.822 313.898 798.407 313.898C812.048 313.898 827.89 306.85 832.946 302.674V323.355Z"
                        fill="white"
                    />
                    <path d="M953.74 162.951H933.051V327.979H953.74V162.951Z" fill="white" />
                    <path
                        d="M1064.18 261.094C1064.18 243.925 1054.06 229.844 1036.24 229.844C1015.99 229.844 1007.63 246.565 1006.09 261.094H1064.18ZM1077.6 323.355C1069.69 326.219 1060.66 330.62 1042.18 330.62C1002.35 330.62 984.096 306.417 984.096 269.887C984.096 236.661 1004.99 213.123 1036.68 213.123C1074.09 213.123 1086.19 240.405 1086.19 276.488H1006.09C1006.09 298.713 1023.48 313.898 1043.06 313.898C1056.7 313.898 1072.54 306.85 1077.6 302.674V323.355Z"
                        fill="white"
                    />
                    <path
                        d="M1167.81 229.843C1145.37 229.843 1134.59 251.189 1134.59 272.094C1134.59 290.792 1144.27 313.898 1167.59 313.898C1189.82 313.898 1199.05 289.032 1198.62 271.43C1199.05 251.189 1190.26 229.843 1167.81 229.843ZM1113.91 162.951H1134.59V231.164H1135.02C1141.4 220.826 1152.63 213.122 1172.21 213.122C1204.78 213.122 1220.62 239.748 1220.62 269.887C1220.62 300.696 1206.31 330.619 1172.43 330.619C1152.41 330.619 1139.64 320.498 1134.14 312.137H1133.71V327.979H1113.91V162.951Z"
                        fill="white"
                    />
                    <path
                        d="M1320.95 261.094C1320.95 243.925 1310.83 229.844 1293 229.844C1272.76 229.844 1264.4 246.565 1262.86 261.094H1320.95ZM1334.36 323.355C1326.45 326.219 1317.43 330.62 1298.95 330.62C1259.12 330.62 1240.85 306.417 1240.85 269.887C1240.85 236.661 1261.76 213.123 1293.44 213.123C1330.85 213.123 1342.96 240.405 1342.96 276.488H1262.86C1262.86 298.713 1280.24 313.898 1299.83 313.898C1313.47 313.898 1329.31 306.85 1334.36 302.674V323.355Z"
                        fill="white"
                    />
                    <path
                        d="M1370.66 215.763H1390.69V233.588H1391.13C1397.29 220.827 1412.25 213.123 1427.88 213.123C1456.92 213.123 1469.89 231.164 1469.89 261.31V327.979H1449.21V269.887C1449.21 243.709 1443.49 230.94 1425.45 229.844C1401.91 229.844 1391.35 248.765 1391.35 276.048V327.979H1370.66V215.763Z"
                        fill="white"
                    />
                    <path
                        d="M355.152 413.144H366.023L382.528 472.253H382.73L397.454 413.144H411.491L426.417 472.253H426.611L443.124 413.144H453.598L432.346 482.136H419.6L404.573 421.844H404.371L389.252 482.136H376.007L355.152 413.144Z"
                        fill="white"
                    />
                    <path
                        d="M462.875 431.726H472.166V482.136H462.875V431.726ZM472.757 420.459H462.283V409.977H472.757V420.459Z"
                        fill="white"
                    />
                    <path d="M499.643 408.008H490.352V482.136H499.643V408.008Z" fill="white" />
                    <path d="M527.119 408.008H517.828V482.136H527.119V408.008Z" fill="white" />
                    <path
                        d="M544.115 408.008H553.406V452.192L573.67 431.727H586.323L562.893 454.067L589.287 482.136H575.942L553.406 456.239V482.136H544.115V408.008Z"
                        fill="white"
                    />
                    <path
                        d="M621.403 475.809C632.274 475.809 637.114 465.926 637.114 456.931C637.114 447.344 631.285 438.053 621.403 438.053C611.419 438.053 605.583 447.344 605.583 456.931C605.583 465.926 610.431 475.809 621.403 475.809ZM621.403 430.543C636.725 430.543 647.004 441.609 647.004 456.931C647.004 471.366 636.523 483.326 621.403 483.326C606.175 483.326 595.7 471.366 595.7 456.931C595.7 441.609 605.98 430.543 621.403 430.543Z"
                        fill="white"
                    />
                    <path
                        d="M659.348 431.726H668.047V439.048H668.242C671.806 433.212 676.841 430.543 684.257 430.543C690.085 430.543 695.921 433.508 698.59 440.325C702.046 433.111 709.649 430.543 714.208 430.543C727.056 430.543 731.701 438.846 731.701 449.818V482.136H722.41V452.091C722.41 445.865 720.931 438.053 713.422 438.053C703.928 438.053 700.17 447.344 700.17 456.736V482.136H690.879V452.091C690.879 445.865 689.4 438.053 681.883 438.053C672.397 438.053 668.639 447.344 668.639 456.736V482.136H659.348V431.726Z"
                        fill="white"
                    />
                    <path
                        d="M747.197 431.726H755.897V439.048H756.099C759.655 433.212 764.698 430.543 772.106 430.543C777.942 430.543 783.778 433.508 786.447 440.325C789.902 433.111 797.513 430.543 802.065 430.543C814.905 430.543 819.551 438.846 819.551 449.818V482.136H810.259V452.091C810.259 445.865 808.781 438.053 801.271 438.053C791.785 438.053 788.02 447.344 788.02 456.736V482.136H778.728V452.091C778.728 445.865 777.25 438.053 769.74 438.053C760.254 438.053 756.488 447.344 756.488 456.736V482.136H747.197V431.726Z"
                        fill="white"
                    />
                    <path
                        d="M867.774 452.091C867.774 444.379 863.223 438.053 855.215 438.053C846.126 438.053 842.368 445.569 841.675 452.091H867.774ZM873.798 480.065C870.241 481.349 866.187 483.326 857.884 483.326C839.994 483.326 831.785 472.448 831.785 456.044C831.785 441.119 841.177 430.543 855.417 430.543C872.218 430.543 877.657 442.799 877.657 459.009H841.675C841.675 468.992 849.48 475.809 858.281 475.809C864.413 475.809 871.526 472.65 873.798 470.774V480.065Z"
                        fill="white"
                    />
                    <path
                        d="M890.094 431.726H899.089V439.733H899.291C902.054 433.999 908.777 430.543 915.803 430.543C928.846 430.543 934.682 438.651 934.682 452.192V482.136H925.39V456.044C925.39 444.278 922.815 438.543 914.707 438.053C904.139 438.053 899.385 446.558 899.385 458.814V482.136H890.094V431.726Z"
                        fill="white"
                    />
                    <path
                        d="M973.21 473.241L1008.99 421.844H974.393V413.144H1020.36V422.039L984.68 473.443H1020.95V482.136H973.21V473.241Z"
                        fill="white"
                    />
                    <path
                        d="M1077.48 482.136H1068.49V474.136H1068.29C1065.52 479.871 1058.8 483.326 1051.77 483.326C1038.73 483.326 1032.9 475.218 1032.9 461.678V431.727H1042.19V457.826C1042.19 469.584 1044.76 475.319 1052.87 475.81C1063.45 475.81 1068.19 467.319 1068.19 455.056V431.727H1077.48V482.136Z"
                        fill="white"
                    />
                    <path
                        d="M1093.27 408.008H1102.56V452.192L1122.84 431.727H1135.49L1112.06 454.067L1138.45 482.136H1125.11L1102.56 456.239V482.136H1093.27V408.008Z"
                        fill="white"
                    />
                    <path
                        d="M1192.81 482.136H1183.81V474.136H1183.62C1180.85 479.871 1174.13 483.326 1167.11 483.326C1154.06 483.326 1148.22 475.218 1148.22 461.678V431.727H1157.52V457.826C1157.52 469.584 1160.09 475.319 1168.2 475.81C1178.77 475.81 1183.52 467.319 1183.52 455.056V431.727H1192.81V482.136Z"
                        fill="white"
                    />
                    <path
                        d="M1208.61 431.726H1217.6V439.733H1217.8C1220.57 433.999 1227.28 430.543 1234.31 430.543C1247.36 430.543 1253.19 438.651 1253.19 452.192V482.136H1243.9V456.044C1243.9 444.278 1241.33 438.543 1233.22 438.053C1222.65 438.053 1217.9 446.558 1217.9 458.814V482.136H1208.61V431.726Z"
                        fill="white"
                    />
                    <path
                        d="M1275.02 439.243H1263.16V431.727H1275.02V427.081C1275.02 414.825 1278.18 406.818 1291.92 406.818C1294.29 406.818 1296.27 407.121 1298.15 407.416L1297.75 414.926C1296.66 414.529 1294.89 414.334 1293.3 414.334C1285.3 414.334 1284.31 419.471 1284.31 426.295V431.727H1297.65V439.243H1284.31V482.136H1275.02V439.243Z"
                        fill="white"
                    />
                    <path
                        d="M1334.22 439.243H1320.87V466.814C1320.87 472.65 1324.43 475.809 1328.68 475.809C1331.55 475.809 1333.63 474.922 1335.01 474.035V481.941C1332.94 482.533 1330.07 483.326 1327.1 483.326C1317.51 483.326 1311.58 478.774 1311.58 468.697V439.243H1300.21V431.726H1311.58V420.069L1320.87 417.104V431.726H1334.22V439.243Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_804_316">
                        <rect width="1470" height="484" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
